import React, {useState, useEffect} from "react";
import { Navigate} from 'react-router-dom'
import {useEnd, useModal, useProfile} from "../context";
import axiosInstance from "../helpers/axios";
import useEffectOnce from "../hooks/useEffectOnce";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Info from "../components/modals/Info";
import InputMask from "react-input-mask";
import Login from "../components/modals/Login";
import Header from "../components/Header";
import Footer from "../components/Footer";

const months = [
    {
        title: 'январь',
        id: 1
    },
    {
        title: 'февраль',
        id: 2
    },
    {
        title: 'март',
        id: 3
    },
    {
        title: 'апрель',
        id: 4
    },
    {
        title: 'май',
        id: 5
    },
    {
        title: 'июнь',
        id: 6
    },
    {
        title: 'июль',
        id: 7
    },
    {
        title: 'август',
        id: 8
    },
    {
        title: 'сентябрь',
        id: 9
    },
    {
        title: 'октябрь',
        id: 10
    },
    {
        title: 'ноябрь',
        id: 11
    },
    {
        title: 'декабрь',
        id: 12
    }
];

function Cabinet() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updatingProfile, setUpdatingProfile] = useState(false);
    const [dateParts, setDateParts] = useState([0, 0, 0]);

    const [date, setDate] = useState('');
    const [dateTitle, setDateTitle] = useState('');
    const [isDateOpen, setIsDateOpen] = useState(false);

    const [month, setMonth] = useState('');
    const [monthTitle, setMonthTitle] = useState('');
    const [isMonthOpen, setIsMonthOpen] = useState(false);

    const [year, setYear] = useState('');
    const [yearTitle, setYearTitle] = useState('');
    const [isYearOpen, setIsYearOpen] = useState(false);

    const [prizes, setPrizes] = useState(null);

    const schemaObj = {
        first_name: yup.string().required('Некорректные ФИО'),
        birthday: yup.string().required('Некорректная дата'),
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        phone:
            yup.string()
                .test("len", 'Некорректный телефон', (val) => {
                    const val_length_without_dashes = val.replace('+', '').replace(/-|_| /g, "").length;
                    return val_length_without_dashes === 11;
                })
                .required('Некорректный телефон'),
    };

    const schema = yup.object(schemaObj).required();

    const { register, clearErrors, setValue, watch, setFocus, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(()=>{
        if(dateParts[0] && dateParts[1] && dateParts[2]){
            setValue('birthday', dateParts.reverse().join('-'))
        }else{
            setValue('birthday', '')
        }
    }, [dateParts]);

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("first_name", data.first_name);
        formData.append("phone", data.phone);
        formData.append("birthday", data.birthday);

        updateProfile(false, formData);
    };

    const updateProfile = async(subscribe = false, formData)=>{
        try {
            const response = await axiosInstance.post('/profiles/api/update-profile', formData);
            if(response.data){
                if(!subscribe){
                    show(
                        <Info
                            title="Спасибо!"
                            text="Данные профиля успешно обновлены."
                            icon={true}
                            close={hide}/>,
                        "modal--info"
                    );
                }else{
                    show(
                        <Info
                            title="Спасибо!"
                            text="Ты&nbsp;успешно подписался на&nbsp;рассылку и&nbsp;участвуешь в&nbsp;розыгрыше ежемесячных призов"
                            icon={true}
                            close={hide}/>,
                        "modal--info"
                    );
                    setProfile({
                        ...profile,
                        data: {
                            ...profile.data,
                            agree_pd: 'yes'
                        }
                    });
                }
            }
            setIsSubmitting(false);
            setUpdatingProfile(false);
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting(false);
            setUpdatingProfile(false);
        }
    }

    const {profile, setProfile} =  useProfile();
    const {end} = useEnd();
    const { show, hide} = useModal();

    useEffect(() => {
        if(!profile.isLoading){
            if(profile.data.phone){
                setValue('phone', profile.data.phone)
            }
            if(profile.data.email){
                setValue('email', profile.data.email)
            }
            if(profile.data.first_name){
                setValue('first_name', profile.data.first_name)
            }
            if(profile.data.birthday){
                const dataArr = profile.data.birthday.split('-').reverse();
                setDateParts(dataArr);
                setDateTitle(dataArr[0]);
                setDate(parseInt(dataArr[0]));
                setMonthTitle(months[parseInt(dataArr[1]) - 1].title);
                setMonth(parseInt(dataArr[1]));
                setYearTitle(dataArr[2]);
                setYear(parseInt(dataArr[2]));
            }
        }
    },[profile]);

    useEffectOnce(()=>{
        getPrizes();
    })

    const getPrizes = async() => {
        try {
            const response = await axiosInstance.get('/prizes/api/profile-prizes?per-page=100&page=1');
            if(response.data){
                setPrizes(response.data.items.reduce((obj, item) => {
                    obj[item.type] = item
                    return obj
                }, {}));
            }
        } catch (error) {
            if(error.response && error.response.data){

            }
        }
    }

    if(!localStorage.getItem('auth_key')){
        return(<Navigate to="/login" replace />)
    }

    return (
        <>
            <Header/>
            <div className="content">
                <div className="sc__lk">
                    <div className="lk-decor-1">
                        <img src={require("../assets/img/lk-decor-1.png")} draggable="false" loading="lazy"/>
                    </div>
                    <div className="lk-decor-2">
                        <img src={require("../assets/img/lk-decor-2.png")} draggable="false" loading="lazy"/>
                    </div>
                    <div className="lk-decor-3">
                        <img src={require("../assets/img/lk-decor-3.png")} draggable="false" loading="lazy"/>
                    </div>
                    <div className="container">
                        <div className="sc__header">
                            <div className="sc__title">
                                личный кабинет
                            </div>
                        </div>
                        <div className="lk__wrap">
                            <div className="lk__box lk__box--profile">
                                <div className="lk__box-hd">
                                    <div className="lk__box-title">
                                        Данные профиля
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                                    <div className="form__list">
                                        <div className="row">
                                            <div className="col-6 form__it">
                                                <div className={'form__field' + (errors.first_name ? ' has-error' : '')}>
                                                    <label htmlFor="email" className="form__label">
                                                        ФИО (полностью)
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="first_name"
                                                        autoComplete="nope"
                                                        placeholder="Введи свое ФИО"
                                                        {...register("first_name")}
                                                    />
                                                </div>
                                                {errors.first_name && (
                                                    <p className="error__message">
                                                        {errors.first_name?.message}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-6 form__it">
                                                <div className={'form__field' + (errors.email ? ' has-error' : '')}>
                                                    <label htmlFor="email" className="form__label">
                                                        e-mail
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="email"
                                                        disabled
                                                        autoComplete="nope"
                                                        placeholder="example@email.ru"
                                                        {...register("email")}
                                                    />
                                                </div>
                                                {errors.email && (
                                                    <p className="error__message">
                                                        {errors.email?.message}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-6 form__it">
                                                <div className="form__label">
                                                    Дата рождения
                                                </div>
                                                <div className="form__select-group">
                                                    <div className={'form__select' + (isDateOpen ? ' active' : '') + (date != '' ? ' has-value' : '') + (errors.birthday ? ' has-error' : '')}>
                                                        <div onClick={()=>{
                                                            setIsDateOpen(!isDateOpen);
                                                            setIsMonthOpen(false);
                                                            setIsYearOpen(false);
                                                        }} className="form__select-input d-flex">
                                                            <div className={'form__select-value' + (date == '' ? ' form__select-value--placeholder' : '')}>
                                                                {dateTitle ? dateTitle : 'День'}
                                                            </div>
                                                            <div className="form__select-toggle">
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.99241 5.85478L12.1185 13.9809L9.99722 16.1022L1.87109 7.9761L3.99241 5.85478Z" fill="#002169"/>
                                                                    <path d="M7.88148 13.9863L16.0131 5.85474L18.1344 7.97606L10.0028 16.1077L7.88148 13.9863Z" fill="#002169"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="form__select-dropdown">
                                                            <div className="form__select-dropdown-list">
                                                                {Array.from({length: 31}).map((it, i) => (
                                                                    <div onClick={()=>{
                                                                        setIsDateOpen(false);
                                                                        setDate((i + 1));
                                                                        setDateTitle(('0' + (i + 1)).slice(-2));
                                                                        setDateParts([('0' + (i + 1)).slice(-2), dateParts[1], dateParts[2]])
                                                                        clearErrors('birthday');
                                                                    }} className={'form__select-dropdown-it' + (it == date ? ' active' : '')}>
                                                                        {('0' + (i + 1)).slice(-2)}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'form__select form__select--month' + (isMonthOpen ? ' active' : '') + (month != '' ? ' has-value' : '') + (errors.birthday ? ' has-error' : '')}>
                                                        <div onClick={()=>{
                                                            setIsMonthOpen(!isMonthOpen);
                                                            setIsDateOpen(false);
                                                            setIsYearOpen(false);
                                                        }} className="form__select-input d-flex">
                                                            <div className={'form__select-value' + (month == '' ? ' form__select-value--placeholder' : '')}>
                                                                {monthTitle ? monthTitle : 'Месяц'}
                                                            </div>
                                                            <div className="form__select-toggle">
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.99241 5.85478L12.1185 13.9809L9.99722 16.1022L1.87109 7.9761L3.99241 5.85478Z" fill="#002169"/>
                                                                    <path d="M7.88148 13.9863L16.0131 5.85474L18.1344 7.97606L10.0028 16.1077L7.88148 13.9863Z" fill="#002169"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="form__select-dropdown">
                                                            <div className="form__select-dropdown-list">
                                                                {months.map((it, i) => (
                                                                    <div onClick={()=>{
                                                                        setIsMonthOpen(false);
                                                                        setMonth(it.id);
                                                                        setMonthTitle(it.title);
                                                                        setDateParts([dateParts[0], ('0' + it.id).slice(-2), dateParts[2]])
                                                                        clearErrors('birthday');
                                                                    }} className={'form__select-dropdown-it' + (it.id == month ? ' active' : '')}>
                                                                        {it.title}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'form__select' + (isYearOpen ? ' active' : '') + (year != '' ? ' has-value' : '') + (errors.birthday ? ' has-error' : '')}>
                                                        <div onClick={()=>{
                                                            setIsYearOpen(!isYearOpen);
                                                            setIsMonthOpen(false);
                                                            setIsDateOpen(false);
                                                        }} className="form__select-input d-flex">
                                                            <div className={'form__select-value' + (year == '' ? ' form__select-value--placeholder' : '')}>
                                                                {yearTitle ? yearTitle : 'Год'}
                                                            </div>
                                                            <div className="form__select-toggle">
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.99241 5.85478L12.1185 13.9809L9.99722 16.1022L1.87109 7.9761L3.99241 5.85478Z" fill="#002169"/>
                                                                    <path d="M7.88148 13.9863L16.0131 5.85474L18.1344 7.97606L10.0028 16.1077L7.88148 13.9863Z" fill="#002169"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="form__select-dropdown">
                                                            <div className="form__select-dropdown-list">
                                                                {Array.from({length: 107}).map((it, i) => (
                                                                    <div onClick={()=>{
                                                                        setIsYearOpen(false);
                                                                        setYear((i + 1900));
                                                                        setYearTitle((i + 1900));
                                                                        setDateParts([dateParts[0], dateParts[1], (i + 1900)])
                                                                        clearErrors('birthday');
                                                                    }} className={'form__select-dropdown-it' + (it == year ? ' active' : '')}>
                                                                        {(i + 1900)}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {errors.birthday && (
                                                    <p className="error__message">
                                                        {errors.birthday?.message}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-6 form__it">
                                                <div className={'form__field' + (errors.phone ? ' has-error' : '')}>
                                                    <label htmlFor="phone" className="form__label">
                                                        Телефон
                                                    </label>
                                                    <InputMask
                                                        autoComplete="nope"
                                                        type="text"
                                                        id="phone"
                                                        mask="+7 999-999-99-99"
                                                        placeholder="+7 999-999-99-99"
                                                        alwaysShowMask={true}
                                                        {...register("phone")}
                                                    />
                                                </div>
                                                {errors.phone && (
                                                    <p className="error__message">
                                                        {errors.phone?.message}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-12 form__it d-flex form__it-justify form__it-center form__bottom">
                                                <button disabled={isSubmitting} type="submit" className="btn btn--auto btn--small d-flex">
                                                    <span>Сохранить изменения</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {!profile.isLoading && Object.keys(profile.data).length > 0 && (
                                <div className="lk__box lk__box--prizes">
                                    <div className="lk__box-hd">
                                        <div className="lk__box-title">
                                            Мои призы
                                        </div>
                                    </div>
                                    <div className="prizes__list">
                                        <div className="prizes__it">
                                            <div className="card">
                                                <div className="card__img">
                                                    <img src={require("../assets/img/c-img-1.jpg")} draggable="false" loading="lazy"/>
                                                </div>
                                                <div className="card__subtitle">
                                                    гарантированный приз
                                                </div>
                                                <div className="card__title">
                                                    промокод в онлайн-<br/>
                                                    кинотеатр
                                                </div>
                                                <div className="prizes__it-status">
                                                    <i className="icon__gift"/>
                                                    <span>отправлен на e-mail</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="prizes__it">
                                            <div className="card">
                                                <div className="card__img">
                                                    <img src={require("../assets/img/c-img-2.jpg")} draggable="false" loading="lazy"/>
                                                </div>
                                                <div className="card__subtitle">
                                                    еженедельные призы
                                                </div>
                                                <div className="card__title">
                                                    брендированные подарки
                                                </div>
                                                {(!prizes || !prizes['2']) && (
                                                    <div className="prizes__it-status">
                                                        <i className="icon__approve"/>
                                                        <span>Ты участвуешь в розыгрыше</span>
                                                    </div>
                                                )}

                                                {(prizes && prizes['2']) && (
                                                    <div className="prizes__it-status">
                                                        <i className="icon__gift"/>
                                                        <span>Ты выиграл</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className={'prizes__it' + ((!prizes || !prizes[1]) ? ' prizes__it--disabled' : '')}>
                                            <div className="card">
                                                <div className="card__img">
                                                    <img src={require("../assets/img/c-img-3.jpg")} draggable="false" loading="lazy"/>
                                                </div>
                                                <div className="card__subtitle">
                                                    ежемесячный приз
                                                </div>
                                                <div className="card__title card__title--small">
                                                    Розыгрыш 15&nbsp;000&nbsp;₽ на&nbsp;события твоего города*
                                                </div>
                                                {(!prizes || !prizes[1]) && (
                                                    <>
                                                        {profile.data.agree_other === 'yes' && (
                                                            <div className="prizes__it-status">
                                                                <i className="icon__approve"/>
                                                                <span>Ты участвуешь в розыгрыше</span>
                                                            </div>
                                                        )}

                                                        {(!profile.data.agree_other || profile.data.agree_other === 'no') && (
                                                            <div className={'prizes__it-action' + (updatingProfile ? ' form--loading' : '')}>
                                                                <div onClick={()=>{
                                                                    const formData = new FormData();
                                                                    formData.append("agree_other", 'yes');
                                                                    setUpdatingProfile(true);
                                                                    updateProfile(true, formData);
                                                                }} className="btn btn--small d-flex">
                                                                    <div className="btn__ct">
                                                                        <div className="btn__ct-title">
                                                                            Подпишись на рассылку
                                                                        </div>
                                                                        <div className="btn__ct-hint">
                                                                            чтобы участвовать в розыгрыше
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}

                                                {(prizes && prizes['1']) && (
                                                    <div className="prizes__it-status">
                                                        <i className="icon__gift"/>
                                                        <span>Ты выиграл</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Cabinet;
