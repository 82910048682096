import React, {useState} from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useModal} from "../../context";
import Info from "./Info";
import axiosInstance from "../../helpers/axios";

function ChangePass(){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const { show, hide} = useModal();

    const schema = yup.object({
        old_password: yup.string().min(6, 'Мин. 6 символов').required('Некорректный пароль'),
        new_password: yup.string().min(6, 'Мин. 6 символов').required(),
        confirm_new_password: yup.string().min(6, 'Мин. 6 символов').oneOf([yup.ref('new_password'), null], 'Пароли не совпадают'),
    }).required();

    const { register, setFocus, watch, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchPass1 = watch("old_password");
    const watchPass2 = watch("new_password");
    const watchPass3 = watch("confirm_new_password");

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("old_password", data.old_password);
        formData.append("new_password", data.new_password);
        formData.append("confirm_new_password", data.confirm_new_password);

        try {
            const response = await axiosInstance.post('/profiles/api/change-password', formData);
            if(response.data){
                show(
                    <Info
                        title="Спасибо!"
                        text="Пароль успешно обновлен."
                        icon={true}
                        close={hide}/>,
                    "modal--info"
                );
            }
            setIsSubmitting(false);
        } catch (error) {
            if(error.response.data){
                error.response.data.map((err, i) => {
                    setError(err.field, { type: 'custom', message: err.message });
                })
            }
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="modal__ct">
                <div className="modal__ct-box">
                    <div className="modal__title">
                        изменить пароль
                    </div>
                </div>
                <div className="modal__ct-box">
                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className={'modal__form' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="row">
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.old_password ? ' has-error' : '') + ((watchPass1 && watchPass1 != '') ? ' has-value' : '')}>
                                        <label htmlFor="old_password" className="form__label">
                                            Старый пароль <span>*</span>
                                        </label>
                                        <input
                                            type="password"
                                            id="old_password"
                                            placeholder="••••••••"
                                            autoComplete="current-password"
                                            {...register("old_password")}
                                        />
                                    </div>
                                    {errors.old_password && (
                                        <p className="error__message">
                                            {errors.old_password?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.new_password ? ' has-error' : '') + ((watchPass2 && watchPass2 != '') ? ' has-value' : '')}>
                                        <label htmlFor="new_password" className="form__label">
                                            Новый пароль <span>*</span>
                                        </label>
                                        <input
                                            type="password"
                                            id="new_password"
                                            placeholder="••••••••"
                                            autoComplete="current-password"
                                            {...register("new_password")}
                                        />
                                    </div>
                                    {errors.new_password && (
                                        <p className="error__message">
                                            {errors.new_password?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it">
                                    <div className={'form__field' + (errors.confirm_new_password ? ' has-error' : '') + ((watchPass3 && watchPass3 != '') ? ' has-value' : '')}>
                                        <label htmlFor="confirm_new_password" className="form__label">
                                            повторите новый пароль <span>*</span>
                                        </label>
                                        <input
                                            type="password"
                                            id="confirm_new_password"
                                            placeholder="••••••••"
                                            autoComplete="current-password"
                                            {...register("confirm_new_password")}
                                        />
                                    </div>
                                    {errors.confirm_new_password && (
                                        <p className="error__message">
                                            {errors.confirm_new_password?.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 form__it form__it--action form__bottom d-flex justify-content-center">
                                    <button disabled={isSubmitting} type="submit" className="btn btn--small btn--auto d-flex align-items-center justify-content-center">
                                        <span>Сохранить новый пароль</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ChangePass;
