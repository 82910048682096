import React, {useState, useEffect} from "react";
import {useEnd, useModal, useProfile} from "../context";
import Login from "../components/modals/Login";
import {goToAnchor} from "../helpers/go-to-anchor";

function LoginPage() {
    const {end} = useEnd();
    const { show, hide} = useModal();

    return (
        <div className="content content--start">
            <div className="sc__start sc__login">
                <div className="sc__start-bg-mb mb-only"/>
                <div onClick={()=>{
                    goToAnchor('login');
                }} className="btn btn--small btn--auto btn__login d-flex mb-only">
                    Войти
                </div>
                <div className="container">
                    <div className="start__img">
                        <picture>
                            <source media="(min-width: 1024px)" srcSet={require("../assets/img/login-img.png")}/>
                            <source media="(max-width: 1023px)" srcSet={require("../assets/img/login-img-sm.png")}/>
                            <img width="637"
                                 height="744"
                                 alt=""
                                 draggable="false" loading="lazy"
                                 src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="/>
                        </picture>

                        <div className="login__header">
                            <div className="login__title">
                                Выигрывай <br className="mb-only"/>драйвовые призы
                            </div>
                            <div className="login__subtitle">
                                и сертификаты на&nbsp;события твоего города
                            </div>
                        </div>

                        <div className="steps__list desk-only">
                            <div className="steps__it d-flex">
                                <div className="steps__it-numb">
                                    01
                                </div>
                                <div className="steps__it-ct">
                                    <div className="steps__it-title">
                                        Зарегистрируйся
                                    </div>
                                    <div className="steps__it-descr">
                                        на сайте и получи гарантированный <br/>
                                        приз: подписку на онлайн-кинотеатр
                                    </div>
                                </div>
                            </div>
                            <div className="steps__it d-flex">
                                <div className="steps__it-numb">
                                    02
                                </div>
                                <div className="steps__it-ct">
                                    <div className="steps__it-title">
                                        участвуй в розыгрыше
                                    </div>
                                    <div className="steps__it-descr">
                                        брендированных подарков и сертификатов на&nbsp;события
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="steps__list mb-only">
                        <div className="steps__it d-flex">
                            <div className="steps__it-numb">
                                01
                            </div>
                            <div className="steps__it-ct">
                                <div className="steps__it-title">
                                    Зарегистрируйся
                                </div>
                                <div className="steps__it-descr">
                                    на сайте и получи гарантированный <br/>
                                    приз: подписку на онлайн-кинотеатр
                                </div>
                            </div>
                        </div>
                        <div className="steps__it d-flex">
                            <div className="steps__it-numb">
                                02
                            </div>
                            <div className="steps__it-ct">
                                <div className="steps__it-title">
                                    участвуй в розыгрыше
                                </div>
                                <div className="steps__it-descr">
                                    брендированных подарков и сертификатов на&nbsp;события
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="login__box modal__content-box">
                        <div id="login" className="anchor"/>
                        <Login/>
                    </div>
                </div>
            </div>
            <div className="start__legal">
                информационная страница промоакции «Лёгкий на подъём»
            </div>
        </div>
    );
}

export default LoginPage;
